import axios from 'axios'
import { persistItem, retrieveItem, removeItem } from './localStorage'

import { RUNTIME_CONFIG_URI } from '../config'

const CONFIG_KEY = 'config'

const persistConfig = config => persistItem(CONFIG_KEY, config)
export const readConfig = () => retrieveItem(CONFIG_KEY)

export const getConfig = async () => {
  let config

  try {
    console.info('Fetching dynamic config...')
    const { data } = await axios.get(RUNTIME_CONFIG_URI)
    config = {
      clientId: data?.client_id,
      clientSecret: data?.client_secret,
      cmsUrl: data?.cms_url,
    }
    persistConfig(config)
  } catch (err) {
    console.error('Error fetching dynamic config', err)
    console.info('Retrieving dynamic config from local storage...')
    config = readConfig()
  }

  console.info('Retrieved config', config)

  return {
    clientId: config?.clientId,
    clientSecret: config?.clientSecret,
    cmsUrl: config?.cmsUrl,
  }
}

export const abandonConfig = () => removeItem(CONFIG_KEY)
