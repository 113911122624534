import React, { PureComponent } from 'react'
import { ReactComponent as Private } from '../Icons/private.svg'

class PrivateIcon extends PureComponent {
  render() {
    return (
      <span className='privateIcon'>
        <Private />
      </span>
    )
  }
}

export default PrivateIcon
