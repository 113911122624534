import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import $ from 'jquery'
import './MatchesNavigator.scss'
import { postMessageToBrowserExtension } from '../../../utils/browserExtension'
import { MESSAGE_FOR_EXTENSION } from '../../../config'

const minMatch = 1

const selectedCssClass = 'highlight--selected'

export const MatchesNavigator = React.memo(
  React.forwardRef(({ markedElements }, ref) => {
    const [selectedMatch, setSelectedMatch] = useState(minMatch)
    const prevSelectedMatch = usePrevious(selectedMatch)
    const maxMatch = markedElements.length
    const goUp = () => {
      if (selectedMatch === minMatch) return
      setSelectedMatch(selectedMatch - 1)
    }
    const goDown = () => {
      if (selectedMatch === maxMatch) return
      setSelectedMatch(selectedMatch + 1)
    }
    useImperativeHandle(ref, () => ({ goUp, goDown }))
    useEffect(() => {
      changeElementColor(markedElements, prevSelectedMatch, false)
    }, [markedElements, prevSelectedMatch])
    useEffect(() => {
      changeElementColor(markedElements, selectedMatch, true).scrollIntoView(true)
    }, [markedElements, selectedMatch])
    useEffect(() => {
      postMessageToBrowserExtension({
        type: MESSAGE_FOR_EXTENSION.MATCH_UP_IS_AVAILABLE,
        payload: selectedMatch !== minMatch,
      })
      postMessageToBrowserExtension({
        type: MESSAGE_FOR_EXTENSION.MATCH_DOW_IS_AVAILABLE,
        payload: selectedMatch !== maxMatch,
      })
    }, [maxMatch, selectedMatch])
    return (
      <div className='matches'>
        <div className='matches__text'>
          Matches {selectedMatch} / {maxMatch}
        </div>
      </div>
    )
  })
)

function changeElementColor(markedElements, elementNumber, add) {
  const domElement = markedElements[elementNumber - 1]
  if (add) {
    $(domElement).addClass(selectedCssClass)
  } else {
    $(domElement).removeClass(selectedCssClass)
  }
  return domElement
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
