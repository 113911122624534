import { path } from 'lodash/fp'

import { createFetchActions } from '../../utils/redux'
import apiClient from '../../utils/apiClient'

export const getContentActions = createFetchActions('GET_CONTENT')

async function preloadRelatedContent(contents, orgId) {
  try {
    for (let i = 0; i < contents.length; i++) {
      const content = contents[i]
      const relatedContentsIds = content.relatedContents || []
      if (!relatedContentsIds.length) continue

      const relatedContentsPromises = relatedContentsIds.map(contentId => {
        return apiClient.getContentById(contentId, orgId).then(path(['data', 'items', 0]))
      })
      const relatedContentsPreloaded = await Promise.all(relatedContentsPromises)
      content.relatedContentsPreloaded = relatedContentsPreloaded
    }

    return contents
  } catch (err) {
    console.error('Error preloading related content for', contents, err)
    return contents
  }
}

/**
 * Retrieves content by Id and loads into the store
 *
 * @param {String} contentId
 * @param {String} orgId - optional org ID
 * @returns {function(...[*]=)}
 */
export function getContentById(contentId, orgId = null) {
  return async dispatch => {
    try {
      dispatch(getContentActions.request())
      const { data } = await apiClient.getContentById(contentId, orgId)
      const contents = await preloadRelatedContent(data.items, orgId)
      dispatch(getContentActions.success({ ...data, items: contents }))
    } catch (err) {
      dispatch(getContentActions.failure(err))
    }
  }
}
