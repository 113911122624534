import React, { useState } from 'react'
import classNames from 'classnames'

import { RegularSmall } from '../Typography/Typography'
import { ReactComponent as ArrowDown } from '../Icons/chevron.svg'
import { ReactComponent as CheckmarkIcon } from '../Icons/checkmark.svg'

import './Dropdown.scss'
import { Popover } from 'react-tiny-popover'

const Dropdown = props => {
  const [isOpen, setIsOpen] = useState(false)
  const { options, selectedOption, className, suppressLabelPreview, onChange } = props

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom']}
      align={'end'}
      padding={3}
      onClickOutside={() => setIsOpen(false)}
      content={() => (
        <ul className='Kb-Dropdown__options'>
          {options.map(option => {
            const isOptionSelected = option.id === selectedOption.id

            return (
              !option.suppressAsOption && (
                <li
                  data-an-event-action={option.dataAnEventAction}
                  key={option.id}
                  className={classNames(
                    'Kb-Dropdown__option',
                    option.disabled ? 'Kb-Dropdown__option--disabled' : 'Kb-Dropdown__option--enabled',
                    !option.disabled && isOptionSelected && 'Kb-Dropdown__option--selected',
                    !option.disabled && !isOptionSelected && 'Kb-Dropdown__option--not-selected'
                  )}
                  onClick={e => {
                    if (option.onSelect) {
                      option.onSelect(e)
                      setIsOpen(false)
                      return
                    }
                    onChange(option.id)
                    setIsOpen(false)
                  }}
                >
                  <div className='Kb-Dropdown__option__contents'>
                    <div className='Kb-Dropdown__option__contents__row'>
                      <div className='Kb-Dropdown__option__item'>
                        <div className='Kb-Dropdown__icon'>{option.icon}</div>
                        {isOptionSelected && <CheckmarkIcon />}
                        <label className='Kb-Dropdown__option-label'>{option.label}</label>
                      </div>
                      {option.upgrade && (
                        <div className='Kb-Dropdown__option__upgrade'>
                          <div className='Kb-Dropdown__option__upgrade__label'>Upgrade</div>
                        </div>
                      )}
                    </div>
                    <div className='Kb-Dropdown__option-description'>{option.description}</div>
                  </div>
                </li>
              )
            )
          })}
        </ul>
      )}
    >
      <div
        className={classNames('Kb-Dropdown', { 'Kb-Dropdown--open': isOpen }, className)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='Kb-Dropdown__content'>
          <div className='Kb-Dropdown__icon'>{selectedOption.icon}</div>
          {selectedOption.label && !suppressLabelPreview && (
            <RegularSmall asTag='label' className='Kb-Dropdown__label'>
              {selectedOption.label}
            </RegularSmall>
          )}
          <div className='Kb-Dropdown__chevron'>
            <ArrowDown />
          </div>
        </div>
      </div>
    </Popover>
  )
}

export default Dropdown
