export const getUrlParams = location => {
  return new URLSearchParams(location.search)
}

export const createScriptEl = src => {
  const script = document.createElement('script')
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('src', src)
  return script
}
