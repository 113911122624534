import React, { Component } from 'react'
import './VideoMarkers.scss'

export default class VideoMarkers extends Component {
  /**
   * Transforms seconds to minutes in display format
   * e.g. 37 => '00:37'
   *
   * @param {string} seconds
   * @returns {string}
   */
  secondsToMinutes = seconds => {
    let min = Math.floor(seconds / 60)
    let sec = seconds - min * 60

    return `${this.padZeroToStart(min)}:${this.padZeroToStart(sec)}`
  }

  /**
   * Pads zero to one digit number
   *
   * @param {number} num
   * @returns {number|string}
   */
  padZeroToStart = num => {
    return num < 10 ? num.toString().padStart(2, '0') : num
  }

  render() {
    return (
      <div className='VideoMarkers'>
        {this.props.markers.map(marker => {
          return (
            <div key={marker.timestamp} className='marker-container'>
              <div onClick={() => this.props.onMarkerClick(marker.timestamp)} className='time'>
                {this.secondsToMinutes(marker.timestamp)}
              </div>

              <div className='title'>{marker.title}</div>
            </div>
          )
        })}
      </div>
    )
  }
}
