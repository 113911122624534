export const UI = {
  ITEM_TYPES: {
    VIDEO: 'Video',
    ARTICLE: 'Article',
    PDF: 'PDF',
  },
}

export const CONTENT_PRIVACY = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
}

export const VIDEO_SOURCE_TYPES = {
  MP4: 'video/mp4',
  YOUTUBE: 'video/youtube',
}

export const RUNTIME_CONFIG_URI = '/config/parameters.json'

/*
Cross origin communication is established via postMessage function
https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
*/
export const MESSAGE_FOR_RESTRICTED_ORIGIN = {
  PDF_ZOOM_IN: 'PDF_ZOOM_IN',
  PDF_ZOOM_OUT: 'PDF_ZOOM_OUT',
  MAXIMIZE_ON: 'MAXIMIZE_ON',
  MAXIMIZE_OFF: 'MAXIMIZE_OFF',
  SET_CURSOR_TOOL: 'SET_CURSOR_TOOL',
  TOGGLE_HIGHLIGHTS: 'TOGGLE_HIGHLIGHTS',
  AUTH_TOKEN: 'AUTH_TOKEN',
  NEXT_MATCH: 'NEXT_MATCH',
  PREVIOUS_MATCH: 'PREVIOUS_MATCH',
  GET_URL_REQUEST: 'GET_URL_REQUEST',
}

export const MESSAGE_FOR_EXTENSION = {
  MATCH_UP_IS_AVAILABLE: 'MATCH_UP_IS_AVAILABLE',
  MATCH_DOW_IS_AVAILABLE: 'MATCH_DOW_IS_AVAILABLE',
  GET_URL_RESPONSE: 'GET_URL_RESPONSE',
}

/**
 * The timeout after which the JWT token with private access will be disregarded
 * and app will fallback to public data delivery
 *
 * @type {Number} time in seconds
 */
export const DEFAULT_SESSION_EXPIRATION_TIME = 3600
