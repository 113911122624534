import React from 'react'
import PropTypes from 'prop-types'

import ToolButton from '../../ToolButton'
import { ReactComponent as CursorIcon } from './ic_cursor.svg'
import { ReactComponent as DragIcon } from './ic_drag.svg'

import './CursorTools.scss'

export const CURSOR_TOOL = {
  HAND: 'hand',
  SELECT: 'select',
}

const CursorTools = ({ onToolSelect, cursorTool }) => (
  <div className='CursorTools'>
    <ToolButton
      isSelected={cursorTool === CURSOR_TOOL.SELECT}
      onClick={() => onToolSelect(CURSOR_TOOL.SELECT)}
      icon={CursorIcon}
    />

    <ToolButton
      isSelected={cursorTool === CURSOR_TOOL.HAND}
      onClick={() => onToolSelect(CURSOR_TOOL.HAND)}
      icon={DragIcon}
    />
  </div>
)

CursorTools.propTypes = {
  onToolSelect: PropTypes.func.isRequired,
  cursorTool: PropTypes.oneOf([CURSOR_TOOL.SELECT, CURSOR_TOOL.HAND]).isRequired,
}

export default CursorTools
