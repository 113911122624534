import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { ReactComponent as ArticleIcon } from '../Icons/content-article.svg'
import { ReactComponent as PdfIcon } from '../Icons/content-pdf.svg'
import { ReactComponent as VideoIcon } from '../Icons/content-video.svg'
import { CONTENT_TYPE } from '../../features/content/consts'

const DEFAULT_SHARING_URL_PREFIX = 'to'

const getContentPreviewPath = (prefix, persistentId, page = null) => {
  const params = `${window.location.origin}/#/${encodeURIComponent(prefix)}/${persistentId}`
  return page ? params + `/${page}` : params
}

export const prerenderRelatedContent = relatedContents => {
  if (!relatedContents || !relatedContents.length) return ''

  return renderToStaticMarkup(
    <div className='RelatedContent'>
      <h3 className='RelatedContent__header'>Related content</h3>
      <ul className='RelatedContent__items'>
        {relatedContents.map(content => (
          <li key={content.id}>
            <a
              className='RelatedContent__item'
              href={getContentPreviewPath(content.title || DEFAULT_SHARING_URL_PREFIX, content.persistentId[0])}
              target='_blank'
              rel='noreferrer'
              data-title={content.title}
            >
              <i>
                {content.type === CONTENT_TYPE.ARTICLE && <ArticleIcon />}
                {content.type === CONTENT_TYPE.VIDEO && <VideoIcon />}
                {content.type === CONTENT_TYPE.PUBLICATION && <PdfIcon />}
              </i>
              <span>{content.title}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
