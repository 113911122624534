import React, { Component } from 'react'
import './Loader.scss'

export default class Header extends Component {
  render() {
    return (
      <div className='Loader'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  }
}
