import { makeFetchSelectors } from '../../utils/redux'

import { CONTENT_REDUCER_NAME } from './consts'
import { createSelector } from 'reselect'

export const contentFetchSelectors = makeFetchSelectors(state => state[CONTENT_REDUCER_NAME])

export const contentByIdSelector = (publicationPersistentId, topicId) =>
  createSelector(contentFetchSelectors.data, content => {
    if (!content) return null
    if (topicId) {
      return content.items.find(item => item.id === topicId)
    }

    return content.items.find(item => item.persistentId.indexOf(publicationPersistentId) > -1)
  })
