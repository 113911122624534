import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './ToolButton.scss'

const ToolButton = ({ isSelected, icon, ...rest }) => {
  const IconComponent = icon

  return (
    <button {...rest} className={classNames('ToolButton', isSelected && 'ToolButton--selected')}>
      <IconComponent />
    </button>
  )
}

ToolButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isSelected: PropTypes.bool,
}

export default ToolButton
