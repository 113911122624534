import React, { Component } from 'react'
import './VideoPlayer.scss'
import PropTypes from 'prop-types'

import { VIDEO_SOURCE_TYPES } from '../../config'

export default class VideoPlayer extends Component {
  static propTypes = {
    poster: PropTypes.string,
    source: PropTypes.shape({
      src: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }

  /**
   * Replaces 'watch' with 'embed' and removes all additional params
   * This is needed for proper rendering and playing in iframe
   *
   * 'rel' param indicates whether the player should show related videos
   * when playback of the initial video ends
   *
   * @param {string} link
   * @return {string}
   */
  transformYoutubeLinkToEmbed = link => {
    let videoUrl
    if (link.indexOf('/watch?v=') > -1) {
      const replacedWatch = link.replace('/watch?v=', '/embed/')
      const queryIndex = replacedWatch.indexOf('&')
      videoUrl = queryIndex > -1 ? replacedWatch.substring(0, queryIndex) : replacedWatch
    } else {
      const replacedWatch = link.replace('youtu.be', 'youtube.com/embed')
      videoUrl = replacedWatch.split('?')[0]
    }

    return `${videoUrl}?rel=0`
  }

  render() {
    const { poster, source } = this.props

    return (
      <div className='VideoPlayer'>
        {source.type === VIDEO_SOURCE_TYPES.YOUTUBE ? (
          <iframe
            className='youtube'
            allowFullScreen
            src={this.transformYoutubeLinkToEmbed(source.src)}
            title='Youtube video player'
            allow='autoplay'
          ></iframe>
        ) : (
          <video controls src={source.src} poster={poster}></video>
        )}
      </div>
    )
  }
}
