import { createAction, handleActions } from 'redux-actions'
import { createSelector } from 'reselect'

export const createFetchActions = actionName => {
  return {
    request: createAction(`${actionName}.REQUEST`),
    failure: createAction(`${actionName}.FAILURE`),
    success: createAction(`${actionName}.SUCCESS`),
  }
}

const INIT_STATE = {
  data: null,
  fetching: false,
  error: null,
}

export const makeFetchReducer = fetchActions => {
  return handleActions(
    {
      [fetchActions.request]: () => ({
        ...INIT_STATE,
        fetching: true,
      }),
      [fetchActions.success]: (state, { payload }) => ({
        ...INIT_STATE,
        data: payload,
        fetching: false,
      }),
      [fetchActions.failure]: (state, { payload }) => ({
        ...INIT_STATE,
        error: payload,
        fetching: false,
      }),
    },
    INIT_STATE
  )
}

export const makeFetchSelectors = baseStateSelector => {
  return {
    data: createSelector(baseStateSelector, state => state.data),
    fetching: createSelector(baseStateSelector, state => state.fetching),
    error: createSelector(baseStateSelector, state => state.error),
  }
}
