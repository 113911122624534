import React, { PureComponent, createRef } from 'react'
import classNames from 'classnames'
import { Document } from 'react-pdf'
import { VariableSizeList } from 'react-window'
import PropTypes from 'prop-types'
import $ from 'jquery'
import 'jquery-highlight'

import { identity, debounce, throttle } from 'lodash/fp'

import PageRenderer from './PageRenderer'

import './PdfPreview.scss'
import './PdfViewer.scss'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { CURSOR_TOOL } from '../ContentPreview/CursorTools/CursorTools'

const RANGE_CHUNK_SIZE = 262144 // 256KiB
const PADDING_TOP = 12
const PADDING_BOTTOM = 12

function isPageInViewport(viewport, pageEl) {
  const pageRect = pageEl.getBoundingClientRect()
  const pageNumber = +pageEl.querySelector('.react-pdf__Page').dataset.pageNumber

  const isInViewport = pageRect.bottom > viewport.top + viewport.height / 2 + 1 && pageRect.top < viewport.bottom
  return {
    isInViewport,
    pageNumber,
  }
}

class PdfPreview extends PureComponent {
  static propTypes = {
    file: PropTypes.string,
    onDocumentLoadSuccess: PropTypes.func,
    options: PropTypes.object,
    scale: PropTypes.number,
    selectedPageIndex: PropTypes.number,
    viewerContainerHeight: PropTypes.number,
    highlightWords: PropTypes.arrayOf(PropTypes.string),
    loader: PropTypes.node,
    cursorTool: PropTypes.oneOf([CURSOR_TOOL.SELECT, CURSOR_TOOL.HAND]).isRequired,
  }

  static defaultProps = {
    onDocumentLoadSuccess: identity,
    options: {},
    scale: 1,
    selectedPageIndex: -1,
    highlightWords: [],
    cursorTool: CURSOR_TOOL.HAND,
  }

  state = {
    pdf: null,
    cachedPageDimensions: null,
    scrollOffset: null,
    currIndex: -1,
  }

  constructor(props) {
    super(props)

    this.$pagesList = createRef()
    this.$document = null
    this.$pagesListParent = null

    this.scrollingToIndex = null

    this.refreshListHeightsDebounced = debounce(300, this.refreshListHeights)
    this.handleScrollThrottled = throttle(300, this.handleScroll)
  }

  componentDidUpdate(prevProps, prevState) {
    const { scale, viewerContainerHeight, cursorTool } = prevProps
    const { cachedPageDimensions } = prevState
    const { selectedPageIndex: newSelectedPageIndex, scale: newScale } = this.props
    const { cachedPageDimensions: newCachedPageDimensions } = this.state

    if (!cachedPageDimensions && newCachedPageDimensions) {
      this.handleScrollTo(newSelectedPageIndex)
    }

    if (this.props.viewerContainerHeight !== viewerContainerHeight || scale !== newScale) {
      this.refreshListHeights()
    }

    if (scale !== newScale && this.state.currIndex > -1 && this.$pagesList.current) {
      this.$pagesList.current.scrollToItem(this.state.currIndex)
    }

    if (this.props.cursorTool !== cursorTool) {
      if (this.props.cursorTool === CURSOR_TOOL.HAND) {
        this.attachDragHandlers()
      } else {
        this.detachDragHandlers()
      }
    }
  }

  componentWillUnmount() {
    this.detachDragHandlers()
  }

  attachDragHandlers = () => {
    if (!this.$pagesListParent) return

    this.$pagesListParent.addEventListener('mousedown', this.handleMouseDown)
    this.$pagesListParent.addEventListener('mouseleave', this.handleMouseLeave)
    this.$pagesListParent.addEventListener('mouseup', this.handleMouseUp)
    this.$pagesListParent.addEventListener('mousemove', this.handleMouseMove)
  }

  detachDragHandlers = () => {
    if (!this.$pagesListParent) return

    this.$pagesListParent.removeEventListener('mousedown', this.handleMouseDown)
    this.$pagesListParent.removeEventListener('mouseleave', this.handleMouseLeave)
    this.$pagesListParent.removeEventListener('mouseup', this.handleMouseUp)
    this.$pagesListParent.removeEventListener('mousemove', this.handleMouseMove)
  }

  handleMouseDown = e => {
    this.isDown = true

    this.startX = e.pageX
    this.scrollLeft = this.$pagesListParent.scrollLeft

    this.startY = e.pageY
    this.scrollTop = this.$pagesListParent.scrollTop
  }

  handleMouseLeave = () => {
    this.isDown = false
  }

  handleMouseUp = () => {
    this.isDown = false
  }

  handleMouseMove = e => {
    if (!this.isDown) return
    e.preventDefault()

    const walkX = e.pageX - this.startX
    this.$pagesListParent.scrollLeft = this.scrollLeft - walkX

    const walkY = e.pageY - this.startY
    this.$pagesListParent.scrollTop = this.scrollTop - walkY
  }

  handleVirtualPagesRender = el => {
    this.$pagesListParent = el

    // attach events here as on componentDidMount there is no this.$pagesListParent yet
    if (el && this.props.cursorTool === CURSOR_TOOL.HAND) {
      this.attachDragHandlers()
    } else if (el && this.props.cursorTool === CURSOR_TOOL.SELECT) {
      this.detachDragHandlers()
    }
  }

  refreshListHeights = () => {
    if (this.$pagesList.current) {
      this.$pagesList.current.resetAfterIndex(0)
    }
  }

  onDocumentLoadSuccess = pdf => {
    this.setState({ pdf })
    this.cachePageDimensions(pdf)
    this.props.onDocumentLoadSuccess(pdf)
  }

  getPageDimensions = (pageNumber, pdf) => {
    return pdf.getPage(pageNumber).then(pdfPage => {
      const viewport = pdfPage.getViewport({ scale: 1 })
      return {
        width: viewport.width,
        height: viewport.height,
      }
    })
  }

  cachePageDimensions(pdf) {
    const pages = Array.from({ length: pdf.numPages }, (v, i) => i)

    // assuming all pages have the same height
    this.getPageDimensions(1, pdf).then(({ width, height }) => {
      const pageDimensions = new Map()

      for (const page of pages) {
        pageDimensions.set(page, {
          width: width,
          height: height,
        })
      }

      this.setState({ cachedPageDimensions: pageDimensions })
      this.props.onPageDimensionsReceived({ width, height })
    })
  }

  computeRowHeight = index => {
    const { scale } = this.props
    const { cachedPageDimensions } = this.state
    const cachedPage = cachedPageDimensions.get(index)
    return scale * cachedPage.height + PADDING_TOP + PADDING_BOTTOM
  }

  handlePageLoaded = page => {
    const { cachedPageDimensions } = this.state

    const viewport = page.getViewport({ scale: 1 })

    const cachedPage = cachedPageDimensions.get(page._pageIndex)
    if (cachedPage.isLoaded) {
      if (page._pageIndex === this.scrollingToIndex) {
        // current scroll position should be fine as it's based on the verified pages dimensions
        // no need to trigger re-render with "resetAfterIndex"
        this.scrollingToIndex = null
      }

      return
    }

    cachedPageDimensions.set(page._pageIndex, {
      height: viewport.height,
      width: viewport.width,
      isLoaded: true,
    })

    this.setState(
      {
        cachedPageDimensions,
      },
      () => {
        // invalidating cached pages dimensions as they could have been affected by just loaded page
        // TODO check if dimensions have really changed
        this.$pagesList.current.resetAfterIndex(page._pageIndex)
      }
    )
  }

  handleScrollTo = pageIndex => {
    if (pageIndex >= 0 && this.$pagesList.current) {
      this.scrollingToIndex = pageIndex
      this.$pagesList.current.scrollToItem(pageIndex)
    }
  }

  tweakAnnotationLayer = () => {
    this.props.highlightWords.forEach(word => $(this.$document).highlight(word, { element: 'mark' }))
  }

  handlePageRenderSuccess = pageIndex => {
    const { cachedPageDimensions } = this.state
    const cachedPage = cachedPageDimensions.get(pageIndex)
    if (cachedPage.isLoaded && pageIndex === this.scrollingToIndex) {
      this.scrollingToIndex = null
      this.$pagesList.current.scrollToItem(pageIndex, 'start')
    }

    this.tweakAnnotationLayer()
  }

  handleGetTextSuccess = () => this.tweakAnnotationLayer()

  handleTogglePagesPreviewOpen = () => this.setState({ isPagesPreviewOpen: !this.state.isPagesPreviewOpen })

  handleItemsRender = ev => {
    const index = (ev.visibleStartIndex + ev.visibleStopIndex) / 2
    this.setState({
      currIndex: Math.round(index <= 0.5 ? 0 : index),
    })
  }

  handleScroll = () => {
    if (this.$pagesListParent) {
      const documentViewport = this.$pagesListParent.getBoundingClientRect()
      const pages = this.$pagesListParent.querySelectorAll('.PdfPreview__Page')

      let pageNumber
      pages.forEach(page => {
        const res = isPageInViewport(documentViewport, page)
        if (res.isInViewport && !pageNumber) {
          pageNumber = res.pageNumber
        }
      })

      this.props.onViewedPageChange(pageNumber)
    }
  }

  renderDocument = el => {
    this.$document = el
  }

  render() {
    const { file, loader, viewerContainerHeight, scale, options, cursorTool, showHighlights } = this.props
    const { pdf, cachedPageDimensions } = this.state

    return (
      <Document
        inputRef={this.renderDocument}
        className={classNames(
          'PdfPreview',
          cursorTool === CURSOR_TOOL.HAND && 'PdfPreview--draggable',
          showHighlights && 'PdfPreview--highlight-query'
        )}
        file={file}
        onLoadSuccess={this.onDocumentLoadSuccess}
        options={{
          disableAutoFetch: true,
          disableStream: true,
          rangeChunkSize: RANGE_CHUNK_SIZE,
          ...options,
        }}
        loading={loader}
        onItemClick={page => this.handleScrollTo(page.pageNumber - 1)}
        externalLinkTarget='_blank'
      >
        {cachedPageDimensions && viewerContainerHeight && (
          <VariableSizeList
            onScroll={this.handleScrollThrottled}
            ref={this.$pagesList}
            outerRef={this.handleVirtualPagesRender}
            itemSize={this.computeRowHeight}
            height={viewerContainerHeight}
            itemCount={pdf.numPages}
            onItemsRendered={this.handleItemsRender}
            itemData={{
              scale,
              loader,
              numPages: pdf.numPages,
              cachedPageDimensions,
              onPageLoadSuccess: this.handlePageLoaded,
              onPageRenderSuccess: this.handlePageRenderSuccess,
              onGetTextSuccess: this.handleGetTextSuccess,
            }}
            overscanCount={1}
          >
            {PageRenderer}
          </VariableSizeList>
        )}
      </Document>
    )
  }
}

export default PdfPreview
