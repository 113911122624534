import React, { Component, createRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { pdfjs } from 'react-pdf'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { CONTENT_PRIVACY, MESSAGE_FOR_RESTRICTED_ORIGIN, MESSAGE_FOR_EXTENSION, UI } from '../../config'
import { readConfig } from '../../utils/configService'
import { getUrlParams, createScriptEl } from '../../utils/common'

import Loader from '../Loader'
import Video from '../Video'
import PdfPreview from '../PdfPreview'
import ToolButton from '../ToolButton'

import CursorTools from './CursorTools'
import { CURSOR_TOOL } from './CursorTools/CursorTools'
import { ReactComponent as CloseIcon } from '../Icons/ic_close.svg'
import { ReactComponent as CopyIcon } from '../Icons/ic_copy.svg'
import { ReactComponent as DownloadIcon } from './ic_download.svg'
import PrivateIcon from '../Icons/PrivateIcon'
import { ReactComponent as URLIcon } from '../Icons/ic_url.svg'
import { ReactComponent as ZoomInIcon } from './ic-zoomin.svg'
import { ReactComponent as ZoomOutIcon } from './ic-zoomout.svg'
import { ReactComponent as GlobeIcon } from '../Icons/globe.svg'
import { prerenderRelatedContent } from './relatedContent'

import './ContentPreview.scss'
import $ from 'jquery'
import 'jquery-highlight'
import { isEmpty } from 'lodash/lang'
import { MatchesNavigator } from './MatchesNavigator'
import Dropdown from '../Dropdown'
import { postMessageToBrowserExtension } from '../../utils/browserExtension'

const ZOOM_LEVEL_MIN = 0.5
const ZOOM_LEVEL_MAX = 4
const ZOOM_LEVEL_STEP = 0.5
const ZOOM_LEVEL_DEFAULT = 1.5

const LOCALES_MAP = {
  en_US: 'English',
  es_MX: 'Spanish',
  fr_CA: 'French',
}

const DEFAULT_SHARING_URL_PREFIX = 'to'

/**
 * The PDF.js requires a worker script loaded by the library in run-time to process the PDF rendering
 * is a separate worker thread for performance consideration. PDF.js created in a way that this script can't be
 * bundled as part of JS bundle built by Webpack. We supply a URL to that file as part of Pdf.JS configuration
 */
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

class ContentPreview extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
      displayType: PropTypes.string.isRequired,
      displayFolderName: PropTypes.string,
      displayTitle: PropTypes.string,
      title: PropTypes.string,
      index: PropTypes.number,
      bodyHtml: PropTypes.object,
      publication: PropTypes.shape({
        bodyPdf: PropTypes.shape({
          fileName: PropTypes.string,
          objectKey: PropTypes.string,
          size: PropTypes.number,
          url: PropTypes.string,
        }),
      }),
      localizedContents: PropTypes.object,
      locale: PropTypes.string,
    }),
    onPreviewClose: PropTypes.func,
    onContentCopy: PropTypes.func,
    onContentUrlCopy: PropTypes.func,
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      dimensions: null,
      isLoading: false,
      contentHTML: null,
      error: null,
      containerExpanded: false,
      pdfPageIndex: props.item.index || 0,
      pdfNumPages: null,
      pdfScale: ZOOM_LEVEL_DEFAULT,
      cursorTool: CURSOR_TOOL.HAND,
      hasHighlights: true,
      hasCssStyleLoadComplete: false,
      markedElements: [],
    }

    this.htmlContentRef = createRef()
    this.viewerContainer = createRef()
    this.matchesRef = createRef()
  }

  componentDidMount() {
    this.handleItemByType(this.props.item.displayType)

    // handle messages coming from parent window to iframe
    window.addEventListener('message', this.messageHandler, false)
    const params = getUrlParams(this.props.location)

    if (params.get('for-iframe')) {
      const cursorTool = params.get('cursor-tool')
      const hasHighlights = params.get('has-highlights')

      if (cursorTool) this.setState({ cursorTool })
      if (hasHighlights) {
        try {
          this.setState({ hasHighlights: JSON.parse(hasHighlights) })
        } catch (err) {}
      }
    }
  }

  componentWillUnmount() {
    if (this.abortController) {
      this.abortController.abort()
    }

    window.removeEventListener('message', this.messageHandler, false)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.item.id !== this.props.item.id) {
      this.handleItemByType(this.props.item.displayType)
    }

    // TODO: get rid of setState() call from within componentDidUpdate()
    if (this.props.item.index && prevProps.item.index !== this.props.item.index) {
      this.setState({
        pdfPageIndex: this.props.item.index,
      })
    }
  }

  renderContentHtml(contentHTML) {
    const params = getUrlParams(this.props.location)
    const urlHighlightKeywords = params.get('for-iframe') && params.get('highlight-keywords')
    const highlightWords = urlHighlightKeywords ? decodeURIComponent(urlHighlightKeywords).split(',') : []
    const markedElements = $('.fr-view')
      .append(contentHTML)
      .highlight(highlightWords, { element: 'mark', wordsOnly: true })
      .find('mark')
    this.setState({ markedElements })
  }

  calculateInitScaleForPage = ({ dimensions }) => {
    return Math.round((this.viewerContainer.current.clientWidth / dimensions.width) * 100) / 100
  }

  messageHandler = event => {
    console.log('    [PubHub - ContentPreview] Message has been received:', event)

    const { data } = event

    if (data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.PDF_ZOOM_IN) {
      return this.handleZoomInClick()
    }

    if (data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.PDF_ZOOM_OUT) {
      return this.handleZoomOutClick()
    }
    if (data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.PREVIOUS_MATCH) {
      return this.handlePreviousMatch()
    }

    if (data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.NEXT_MATCH) {
      return this.handleNextMatch()
    }

    if (
      data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.MAXIMIZE_ON ||
      data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.MAXIMIZE_OFF
    ) {
      const { dimensions } = this.state
      if (dimensions) {
        this.setState({ pdfScale: this.calculateInitScaleForPage({ dimensions }) })
      }

      return
    }

    if (data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.SET_CURSOR_TOOL) {
      return this.setState({ cursorTool: data.payload.cursorTool })
    }

    if (data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.TOGGLE_HIGHLIGHTS) {
      return this.setState({ hasHighlights: data.payload.hasHighlights })
    }

    if (data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.GET_URL_REQUEST) {
      // remove iframe query params
      const urlForShare = window.location.href.replace(this.props.location.search, '')

      return postMessageToBrowserExtension({
        type: MESSAGE_FOR_EXTENSION.GET_URL_RESPONSE,
        payload: { link: urlForShare, contentItem: this.props.item },
        action: data.action,
      })
    }
  }

  handleItemByType = type => {
    switch (type) {
      case UI.ITEM_TYPES.ARTICLE:
        this.getArticleContent()
        break
      case UI.ITEM_TYPES.VIDEO:
        this.setState({ error: null, contentHTML: null, isLoading: false })
        break
      case UI.ITEM_TYPES.PDF:
        this.setState({
          error: null,
          ontentHTML: null,
          isLoading: false,
          pdfPageIndex: this.props.item.index,
          pdfNumPages: null,
          pdfScale: ZOOM_LEVEL_DEFAULT,
        })
        break
      default:
        this.setState({ error: `Content type ${type} isn't supported.`, contentHTML: null, isLoading: false })
        break
    }
  }

  adjustHtmlLinks = () => {
    if (!this.htmlContentRef.current) return

    const links = this.htmlContentRef.current.querySelectorAll('a')
    links.forEach(link => link.setAttribute('target', '_blank'))
  }

  tweakHtmlContent = () => {
    this.handleImagesWithHotspots()
    this.adjustHtmlLinks()
  }

  getArticleContent = () => {
    if (this.abortController) {
      this.abortController.abort()
    }

    // aborting ongoing requests
    // https://dom.spec.whatwg.org/#aborting-ongoing-activities
    this.abortController = new AbortController()
    this.abortSignal = this.abortController.signal

    this.setState(
      {
        error: null,
        contentHTML: null,
        isLoading: true,
      },
      () => {
        const { relatedContents } = this.props
        this.getHTMLContent().then(html => {
          this.setState(
            {
              contentHTML: this.injectHtml(html, prerenderRelatedContent(relatedContents)),
              isLoading: false,
            },
            () => {
              this.tweakHtmlContent()
              const scriptEl = createScriptEl(`${readConfig().cmsUrl}/api/v1/js/content-preview.js`)
              document.body.appendChild(scriptEl)
            }
          )
        })
      }
    )
  }

  getHTMLContent = () => {
    // Temporary workaround for https://tweddle.atlassian.net/browse/OI-2009
    // TODO: Remove the quick fix and reconfigure AWS CloudFront / S3 to avoid the CORS + caching issue
    const timestampParam = '?ts=' + Date.now().toString()
    return fetch(this.props.item.bodyHtml.url + timestampParam, { signal: this.abortSignal })
      .then(res => res.text())
      .catch(e => {
        // don't consider aborts as errors
        if (e.code === 20) {
          return
        }

        console.error(e)
        this.setState({ error: e, isLoading: false })
      })
  }

  handleImagesWithHotspots = () => {
    const { imageMetadata } = this.props.item

    if (!imageMetadata || !imageMetadata.length) {
      return
    }

    imageMetadata.forEach(metadata => {
      const metaImageID = metadata.imageId
      const imageEl = Array.from(this.htmlContentRef.current.querySelectorAll('img')).find(
        imgEl => imgEl.dataset.imageId === metaImageID
      )

      if (!imageEl) {
        return
      }

      imageEl.onload = () => this.appendHotSpots(imageEl, metadata)
    })
  }

  /**
   * Creates hotspot elements and appends to image container
   */
  appendHotSpots = (imageEl, metadata) => {
    const imageParentEl = imageEl.parentNode

    metadata.hotspots.forEach(hotspot => {
      let hotspotEl = document.createElement('a')

      hotspotEl.href = '#'
      hotspotEl.onclick = e => {
        e.preventDefault()
      }

      hotspotEl.classList.add('hotspot-link')
      hotspotEl.textContent = hotspot.title

      imageParentEl.appendChild(hotspotEl)

      const imageContainerWidth = imageParentEl.offsetWidth
      const imageContainerHeight = imageParentEl.offsetHeight

      const widthScale = imageContainerWidth / metadata.refWidth
      const heightScale = imageContainerHeight / metadata.refHeight

      const hotspotRadius = hotspotEl.offsetWidth / 2

      const left = hotspot.x * widthScale - hotspotRadius
      const top = hotspot.y * heightScale - hotspotRadius

      hotspotEl.style.left = (left / imageContainerWidth) * 100 + '%'
      hotspotEl.style.top = (top / imageContainerHeight) * 100 + '%'
    })
  }

  toggleContainerHeight = () => {
    this.setState({ containerExpanded: !this.state.containerExpanded })
  }

  handleZoomInClick = () => {
    const scale = this.state.pdfScale + ZOOM_LEVEL_STEP
    if (scale < ZOOM_LEVEL_MAX) {
      this.setState({
        pdfScale: scale,
      })
    }
  }

  handleZoomOutClick = () => {
    const scale = this.state.pdfScale - ZOOM_LEVEL_STEP
    if (scale >= ZOOM_LEVEL_MIN) {
      this.setState({
        pdfScale: scale,
      })
    }
  }

  handlePreviousMatch = () => {
    this.matchesRef.current?.goUp()
  }

  handleNextMatch = () => {
    this.matchesRef.current?.goDown()
  }

  /**
   * Stores total number of pages to local state, as we need it for page navigation
   * @param {Number} numPages
   */
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ pdfNumPages: numPages })
  }

  /**
   * Removes redundant elements ('META', 'LINK', 'TITLE', 'HEAD')
   *
   * TODO: move this to utils
   *
   * @param {String} html
   * @returns {String}
   */
  injectHtml = (html, relatedContentHtml) => {
    if (!html) return ''

    const blackListTags = ['META', 'LINK', 'TITLE', 'HEAD']

    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    const tags = doc.getElementsByTagName('*')
    const elementsToRemove = []

    for (let i = 0; i < tags.length; i++) {
      const node = tags[i]

      if (blackListTags.includes(node.tagName)) {
        elementsToRemove.push(node)
      }
    }

    elementsToRemove.forEach(node => node.parentNode.removeChild(node))

    const XMLS = new XMLSerializer()
    const contentHtml = XMLS.serializeToString(doc)
    this.renderContentHtml(contentHtml + relatedContentHtml)
    return contentHtml
  }

  handleViewedPageChange = pdfPageIndex => {
    if (pdfPageIndex && pdfPageIndex - 1 !== this.state.pdfPageIndex) {
      this.setState({ pdfPageIndex: pdfPageIndex - 1 })
    }
  }

  handleDimensionsReceived = dimensions => {
    this.setState({
      dimensions,
      pdfScale: this.calculateInitScaleForPage({ dimensions }),
    })
  }

  handleCursorToolSelect = cursorTool => {
    this.setState({ cursorTool })
  }

  renderContent = () => {
    const { cursorTool, hasHighlights } = this.state
    const { item } = this.props
    if (item.displayType === UI.ITEM_TYPES.ARTICLE) {
      return (
        <>
          {ReactDOM.createPortal(
            <link
              rel='stylesheet'
              href={`${readConfig().cmsUrl}/api/v1/css/pubhub-article.css`}
              type='text/css'
              onLoad={() => this.setState({ hasCssStyleLoadComplete: true })}
              onError={() => this.setState({ hasCssStyleLoadComplete: true })}
            />,
            document.head
          )}
          <div
            style={{ display: this.state.hasCssStyleLoadComplete ? 'block' : 'none' }}
            className={classNames('html-content', 'fr-view', !hasHighlights && 'unHighlighted')}
            ref={this.htmlContentRef}
          />
        </>
      )
    }

    if (item.displayType === UI.ITEM_TYPES.VIDEO) {
      return <Video video={item} />
    }

    if (item.displayType === UI.ITEM_TYPES.PDF && this.viewerContainer.current) {
      const params = getUrlParams(this.props.location)
      const urlHighlightKeywords = params.get('for-iframe') && params.get('highlight-keywords')
      const highlightWords = urlHighlightKeywords ? decodeURIComponent(urlHighlightKeywords).split(',') : []
      return (
        <PdfPreview
          file={this.props.item.bodyPdf.url}
          size={this.props.item.bodyPdf.size}
          selectedPageIndex={this.state.pdfPageIndex}
          scale={this.state.pdfScale}
          onDocumentLoadSuccess={this.onDocumentLoadSuccess}
          viewerContainerHeight={this.viewerContainer.current.clientHeight}
          loader={<Loader />}
          onViewedPageChange={this.handleViewedPageChange}
          onPageDimensionsReceived={this.handleDimensionsReceived}
          cursorTool={cursorTool}
          highlightWords={highlightWords}
          showHighlights={hasHighlights}
        />
      )
    }

    return null
  }

  /**
   * Renders content of the footer left panel for PDF preview
   *
   * @return {React.View}
   */
  renderFooterLeftPanelPdf = () => {
    if (this.state.pdfNumPages != null) {
      return (
        <span>
          Page {this.state.pdfPageIndex + 1} of {this.state.pdfNumPages}
        </span>
      )
    }
  }

  downloadPDF = () => {
    window.open(this.props.item.bodyPdf.url, '_blank')
  }

  /**
   * Renders toolbar control
   *
   * @return {React.View[]}
   */
  renderPdfControls = () => {
    return (
      <>
        <ToolButton onClick={this.handleZoomInClick} icon={ZoomInIcon} />
        <ToolButton onClick={this.handleZoomOutClick} icon={ZoomOutIcon} />
        <ToolButton onClick={this.downloadPDF} icon={DownloadIcon} />
      </>
    )
  }

  handleLocaleChange = locale => {
    const { navigate, location, item } = this.props

    navigate(`/${DEFAULT_SHARING_URL_PREFIX}/${item.localizedContents[locale]}${location.search}`)
  }

  render() {
    const { isLoading, error, containerExpanded, cursorTool, hasCssStyleLoadComplete } = this.state
    const { item } = this.props
    const params = getUrlParams(this.props.location)
    const markedElements = this.state.markedElements

    return (
      <div
        className={classNames(
          'ContentPreview',
          containerExpanded && 'expanded',
          params.get('for-iframe') && 'for-iframe'
        )}
      >
        <div className={classNames('MainContainer', item.displayType)}>
          <div className='Header'>
            <div className='Header__info'>
              <div>
                {!!item.displayFolderName && `${item.displayFolderName} | `}
                {item.displayType}
                {item.privacy === CONTENT_PRIVACY.Private && <PrivateIcon />}
              </div>
              {item.displayType === UI.ITEM_TYPES.ARTICLE &&
                !isEmpty(markedElements) &&
                !isLoading &&
                hasCssStyleLoadComplete && <MatchesNavigator ref={this.matchesRef} markedElements={markedElements} />}

              {item.localizedContents && Object.values(item.localizedContents).length > 1 && (
                <div className='Header__langugaes'>
                  <Dropdown
                    options={Object.keys(item.localizedContents).map(locale => ({
                      id: locale,
                      label: LOCALES_MAP[locale],
                    }))}
                    selectedOption={{ id: item.locale, label: LOCALES_MAP[item.locale], icon: <GlobeIcon /> }}
                    onChange={this.handleLocaleChange}
                  />
                </div>
              )}

              {item.displayType === UI.ITEM_TYPES.PDF && (
                <div className='Header__pages'>{this.renderFooterLeftPanelPdf()}</div>
              )}
            </div>

            <div className='Header__title'>
              {item.displayType === UI.ITEM_TYPES.PDF ? item.title : item.displayTitle}
            </div>
          </div>

          <div className='Content'>
            {isLoading && (
              <div className='LoaderWrapper'>
                <Loader />
              </div>
            )}
            {error ? (
              <div className='LoadError'>
                <h2>
                  Oups, unable to load the content. Please
                  <button className='LoadError__retry-btn' onClick={() => window.location.reload()}>
                    retry
                  </button>
                </h2>
              </div>
            ) : (
              <div
                className={`content-container toc-preview__topic-container ${item.displayType}`}
                ref={this.viewerContainer}
              >
                {this.renderContent()}
              </div>
            )}
          </div>
        </div>

        {item.displayType === UI.ITEM_TYPES.PDF && (
          <div className='ToolsPanel'>
            <div className='TopActions'>
              {this.props.onPreviewClose && <CloseIcon onClick={this.props.onPreviewClose} className='icon' />}
              <CursorTools onToolSelect={this.handleCursorToolSelect} cursorTool={cursorTool} />
            </div>
            <div className='BottomActions'>
              {item.displayType === UI.ITEM_TYPES.PDF && this.renderPdfControls()}
              {this.props.onContentCopy && <CopyIcon onClick={this.props.onContentCopy} className='icon' />}
              {this.props.onContentUrlCopy && <URLIcon onClick={this.props.onContentUrlCopy} className='icon' />}
            </div>
          </div>
        )}
      </div>
    )
  }
}

const ContentPreviewGuard = props => {
  const location = useLocation()
  const navigate = useNavigate()

  return <ContentPreview {...props} location={location} navigate={navigate} />
}

export default ContentPreviewGuard
