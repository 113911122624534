import { UI } from '../../config'

/**
 * Processes raw content received from server
 * Adds the following fields to display on UI: displayTitle, displayFolderName, displayType
 * @param {Object} item
 * @param {Number} page
 * @returns {Object}
 */
export function prepareContent(item, page) {
  switch (item.type) {
    case 'videos':
    case 'video':
      return {
        ...item,
        displayTitle: item.title,
        displayFolderName: getSearchItemName(item),
        displayType: UI.ITEM_TYPES.VIDEO,
      }
    case 'publications':
    case 'publication':
      return {
        ...item,
        displayTitle: item.title,
        displayFolderName: getSearchItemName(item),
        displayType: UI.ITEM_TYPES.PDF,
        index: page - 1,
      }
    default:
      return {
        ...item,
        displayTitle: item.title,
        displayFolderName: getSearchItemName(item),
        displayType: UI.ITEM_TYPES.ARTICLE,
      }
  }
}

/**
 * Retrieves the folder name from search item
 *
 * @param {Object} item
 * @returns {String}
 */
export function getSearchItemName(item) {
  if (item.folders) {
    return item.folders[0]?.title
  }
  return null
}
