import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import './App.scss'
import GuideContent from './components/Guide/GuideContent'

import createStore from './store'
import { getConfig } from './utils/configService'

import { sendErrorLog } from './utils/logger'

export class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.store = createStore()

    getConfig().then(() => {
      this.setState({ loading: false })
    })
  }

  render() {
    if (this.state.loading) return null

    return (
      <React.StrictMode>
        <Provider store={this.store}>
          <ErrorBoundary FallbackComponent={() => <div>Something went wrong</div>} onError={sendErrorLog}>
            <div className='App'>
              <HashRouter>
                <Routes>
                  <Route
                    path='/'
                    element={
                      <div>
                        <h1>PubHub</h1>
                        <h4>An Intelligent Helpdesk Plugin</h4>
                      </div>
                    }
                  />
                  <Route path='/:slug/:id'>
                    <Route index Component={GuideContent} />
                    <Route path=':page?' Component={GuideContent} />
                    <Route path='topics/:topicId' Component={GuideContent} />
                  </Route>

                  <Route path='*' element={<div>404 Not found</div>} />
                </Routes>
              </HashRouter>
            </div>
          </ErrorBoundary>
        </Provider>
      </React.StrictMode>
    )
  }
}

export default App
