import React, { Component } from 'react'
import './Video.scss'
import PropTypes from 'prop-types'

import { VIDEO_SOURCE_TYPES } from '../../config'
import VideoPlayer from '../VideoPlayer'
import VideoMarkers from '../VideoMarkers'

export default class Video extends Component {
  static propTypes = {
    video: PropTypes.shape({
      id: PropTypes.string,
      thumbnailFile: PropTypes.object,
      externalLink: PropTypes.string,
    }),
  }

  constructor(props) {
    super(props)

    this.state = {
      videoSource: null,
    }
  }

  componentDidMount() {
    this.setState({ videoSource: this.getVideoSource(this.props.video) })
  }

  componentDidUpdate(prevProps) {
    const prevSource = this.getVideoSource(prevProps.video)
    const currentSource = this.getVideoSource(this.props.video)

    if (prevSource && prevSource.src !== currentSource.src) {
      this.setState({ videoSource: currentSource })
    }
  }

  getVideoSource = video => {
    const file = video.file
    const externalLink = video.externalLink

    if (this.isYouTubeUrl(externalLink)) {
      return {
        src: externalLink,
        type: VIDEO_SOURCE_TYPES.YOUTUBE,
      }
    }

    if (file) {
      return {
        thumbnail: video.thumbnailFile && video.thumbnailFile.url,
        src: file.url,
        type: VIDEO_SOURCE_TYPES.MP4,
      }
    }

    if (externalLink) {
      return {
        thumbnail: video.thumbnailLink,
        src: externalLink,
        type: VIDEO_SOURCE_TYPES.MP4,
      }
    }

    return null
  }

  isYouTubeUrl = url => {
    const regExp = /^https?:\/\/(w{3}.|m.)?youtu?.be/gm
    return !!(url && url.match(regExp))
  }

  playVideoFrom = time => {
    if (this.state.videoSource.type === VIDEO_SOURCE_TYPES.MP4) {
      const videoEl = document.querySelector('.VideoPlayer video')

      videoEl.currentTime = time
      videoEl.play()
    } else if (this.state.videoSource.type === VIDEO_SOURCE_TYPES.YOUTUBE) {
      const youtubeVideoFrame = document.querySelector('iframe.youtube')

      // clear prev values and add new ones
      youtubeVideoFrame.src =
        youtubeVideoFrame.src.replace(/&autoplay=1&start=[0-9]*/g, '') + `&autoplay=1&start=${time}`
    }
  }

  render() {
    const { videoSource } = this.state
    const { video } = this.props

    if (!videoSource) {
      return <h2>Video not available</h2>
    }

    const videoProps = {
      poster: videoSource.thumbnail,
      source: {
        src: videoSource.src,
        type: videoSource.type,
      },
    }

    return (
      <div className='Video'>
        <VideoPlayer {...videoProps} />

        {video.markers && <VideoMarkers onMarkerClick={time => this.playVideoFrom(time)} markers={video.markers} />}
      </div>
    )
  }
}
