import React, { PureComponent } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'

import { MESSAGE_FOR_RESTRICTED_ORIGIN, DEFAULT_SESSION_EXPIRATION_TIME } from '../../config'
import { getUrlParams } from '../../utils/common'
import Loader from '../Loader'
import ContentPreview from '../ContentPreview'
import { getContentById } from '../../features/content/actions'
import { prepareContent } from '../../features/content/utils'
import apiClient from '../../utils/apiClient'
import { persistAuthToken } from '../../utils/apiClient'

import { contentFetchSelectors, contentByIdSelector } from '../../features/content/selectors'

import './Guide.scss'

export class GuideContent extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
    }
  }

  static propTypes = {
    getContentById: PropTypes.func.isRequired,
    isContentFetching: PropTypes.bool,
    contentItem: PropTypes.object,
  }

  async componentDidMount() {
    const params = getUrlParams(this.props.location)
    const isPrivate = params.get('private')
    if (isPrivate) {
      // subscribe for authentication token arrival from parent window
      return window.addEventListener('message', this.messageHandler, false)
    } else {
      apiClient.unauthenticate()
    }

    await this.props.getContentById(this.props.id)
    this.setState({ isInitialized: true })
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props
    if (id !== prevProps.id) {
      this.props.getContentById(id)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler, false)
  }

  messageHandler = event => {
    const { data } = event
    if (data.type === MESSAGE_FOR_RESTRICTED_ORIGIN.AUTH_TOKEN) {
      persistAuthToken({
        // TODO: read the actual expiration time for the token
        accessToken: data.payload.authToken,
        expiresIn: DEFAULT_SESSION_EXPIRATION_TIME,
      })
      apiClient.authenticateClient()
      this.setState(
        {
          organizationId: data.payload.organizationId,
        },
        async () => {
          await this.props.getContentById(this.props.id, data.payload.organizationId)
          this.setState({ isInitialized: true })
        }
      )
    }
  }

  render() {
    const { isContentFetching, contentItem, page } = this.props

    const { isInitialized } = this.state

    if (isContentFetching || !isInitialized) {
      return (
        <div className='Guide__loader'>
          <Loader />
        </div>
      )
    }

    if (!contentItem) {
      return <div className='error'>Not Found</div>
    }

    const currentPage = parseInt(page, 10) || 1
    return (
      <ContentPreview
        item={prepareContent(contentItem, currentPage)}
        relatedContents={contentItem.relatedContentsPreloaded}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getContentById: (id, orgId) => dispatch(getContentById(id, orgId)),
  }
}

const GuideContentGuard = props => {
  const { id, page, topicId } = useParams()
  const location = useLocation()
  const contentItem = useSelector(contentByIdSelector(id, topicId))
  const isContentFetching = useSelector(contentFetchSelectors.fetching)

  return (
    <GuideContent
      {...props}
      id={id}
      page={page}
      topicId={topicId}
      contentItem={contentItem}
      isContentFetching={isContentFetching}
      location={location}
    />
  )
}

export default connect(null, mapDispatchToProps)(GuideContentGuard)
