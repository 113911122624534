export const persistItem = (key, item) => localStorage.setItem(key, JSON.stringify(item))
export const retrieveItem = key => {
  try {
    const jsonStr = localStorage.getItem(key)
    if (jsonStr) return JSON.parse(jsonStr)
  } catch (err) {}

  return null
}
export const removeItem = key => localStorage.removeItem(key)
