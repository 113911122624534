import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Typography.scss'

const Text = ({ initClassName, className, bold, semibold, asTag = 'div', centered, ...rest }) => {
  const TextComponent = asTag
  return (
    <TextComponent
      className={classNames(
        initClassName,
        semibold && 'Typography--semibold',
        bold && 'Typography--bold',
        centered && 'Typography--centered',
        className
      )}
      {...rest}
    />
  )
}

Text.propTypes = {
  initClassName: PropTypes.string,
  className: PropTypes.string,
  semibold: PropTypes.bool,
  asTag: PropTypes.string,
}

export const BlockTitle = ({ className, disabled, ...rest }) => (
  <Text
    initClassName='Typography__block-title'
    className={classNames(disabled && 'Typography__block-title--disabled', className)}
    {...rest}
  />
)

export const SectionTitle = props => <Text initClassName='Typography__section-title' {...props} />

export const BigTitle = props => <Text initClassName='Typography__big-title' {...props} />

export const SectionHighlight = props => <Text initClassName='Typography__section-highlight' {...props} />

export const Caption = props => <Text initClassName='Typography__caption' {...props} />

export const Regular = props => <Text initClassName='Typography__regular' {...props} />

export const RegularSmall = props => <Text initClassName='Typography__regular--small' {...props} />

export const ControlText = props => <Text asTag='span' initClassName='Typography__control-text' {...props} />

export const ContentText = props => <Text initClassName='Typography__content-text' {...props} />

export const CaptionRegular = props => <Text initClassName='Typography__caption-regular' {...props} />
