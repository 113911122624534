import axios from 'axios'
import qs from 'qs'

import { persistItem, removeItem, retrieveItem } from './localStorage'
import { abandonConfig, getConfig } from './configService'
import { sendErrorLog } from './logger'

const ENDPOINTS = {
  AUTH: '/api/v3/auth/token',
  CONTENT: '/api/v3/contents/search',
}

const AUTH_KEY = 'auth'

const authenticate = ({ clientId, clientSecret } = {}) =>
  axios.post(
    ENDPOINTS.AUTH,
    qs.stringify({
      grant_type: 'client_credentials',
      client_id: clientId,
      client_secret: clientSecret,
    }),
    {
      headers: {
        'tw-cookie-authentication': 'true',
      },
      withCredentials: true,
    }
  )

export const isTokenExpired = expiryDate => expiryDate < new Date().getTime()
export const clearAuthToken = () => removeItem(AUTH_KEY)
export const persistAuthToken = ({ accessToken, expiresIn }) =>
  persistItem(AUTH_KEY, {
    accessToken,
    expiryDate: new Date().getTime() + expiresIn * 1000,
  })
const retrieveAuthToken = () => retrieveItem(AUTH_KEY)
const isAuthValid = authData => authData && !isTokenExpired(authData.expiryDate)

class ApiClient {
  constructor() {
    this.authPromise = null
    this.authData = null

    axios.interceptors.response.use(undefined, error => {
      const response = error.response
      if (response && response.status >= 400 && response.status !== 401 && response.status !== 403) {
        sendErrorLog(error)
      }

      return Promise.reject(error)
    })

    this.authenticateClient()
  }

  authenticateClient = () => {
    console.info('Authenticating API Client')
    const authData = retrieveAuthToken()
    this.authData = isAuthValid(authData) ? authData : null
    if (this.authData) {
      console.log('API Client authentication has been completed with auth data', this.authData)
      this.setAuthToken(this.authData.accessToken)
    }
  }

  setAuthToken = token => {
    axios.defaults.baseURL = ''
    axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null
  }

  isAuthenticated = () => isAuthValid(this.authData)

  authenticate = () => {
    if (!this.authPromise) {
      this.authPromise = new Promise(async (resolve, reject) => {
        const { clientId, clientSecret } = await getConfig()

        authenticate({ clientId, clientSecret })
          .then(({ data }) => {
            console.info('Authentication request has been successful', data)
            persistAuthToken(data)
            this.authenticateClient()

            this.authPromise = null
            resolve()
          })
          .catch(err => {
            console.error('Error authenticating API Client', err)
            this.authPromise = null
            reject(err)
          })
      })
    }

    return this.authPromise
  }

  unauthenticate = () => {
    console.info('Unauthenticating API Client...')
    this.authData = null
    axios.defaults.headers.common['Authorization'] = null
    clearAuthToken()
    abandonConfig()
  }

  fetchAuthenticated = async (request, isRetry) => {
    try {
      if (!this.isAuthenticated()) {
        console.info('Authenticating API Client...')
        await this.authenticate()
      }

      console.info('API Client is authenticated, fetching the data...')
      return await axios.request(request)
    } catch (err) {
      console.error('Error fetching data for request', request, err.response)

      if (err.response && (err.response.status === 403 || err.response.status === 401)) {
        this.unauthenticate()
        if (!isRetry) {
          await this.fetchAuthenticated(request, true)
        }
      } else {
        throw err
      }
    }
  }

  /**
   * Returns content by ID
   *
   * @param {String} contentId
   * @param {String} orgId
   */
  getContentById = (contentId, orgId = null) => {
    const params = {
      persistentId: contentId,
    }
    if (orgId) {
      params.organizationId = orgId
    }
    return this.fetchAuthenticated({
      method: 'post',
      url: ENDPOINTS.CONTENT,
      data: params,
    })
  }
}

export default new ApiClient()
